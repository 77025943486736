<template>
  <div class="min-h-full relative max-h-full flex flex-col text-base-content">
    <sub-header
      class="mb-4"
      :backButton="true"
      :customCrumbLists="customBreadCrumbs"
    />
    <div class="flex-1 relative bg-card-bg rounded-md shadow">
      <div
        v-if="true"
        class="px-4 lg:px-5 py-3 flex gap-3 items-center bg-primary-300 border-b border-gray-200 text-base-content-600 text-lg font-medium h-10"
      >
        <span class="font-bold">{{ statusDetail.name || "Loading..." }} </span>
      </div>
      <div v-if="!configLoader" class="flex w-full flex-col p-4 justify-center">
        <div>
          <create-status
            workflowType="globalCase"
            :mode="mode"
            :status="statusDetail"
          />
        </div>
        <div class="flex w-full justify-center items-center">
          <div class="relative bg-card-bg rounded-md shadow flex-1">
            <div
              v-if="true"
              class="px-4 lg:px-5 py-1 flex gap-3 items-center border-b border-gray-200 text-base-content-600 text-sm font-medium rounded-t-lg bg-gray-200"
            >
              Status can be updated to any of the following.
            </div>
            <div class="flex w-full flex-col p-3 justify-center">
              <div class="flex justify-center items-center gap-10">
                <div
                  class="flex border-2 items-center justify-center p-4 rounded-lg"
                >
                  <StatusBadge
                    :label="statusDetail.name"
                    :color="statusDetail.colour"
                    customClass="rounded-full"
                  />
                </div>
                <div class="flex p-3">
                  <svg
                    width="47"
                    height="10"
                    viewBox="0 0 47 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M46.2067 5.70664C46.5974 5.31602 46.5974 4.68164 46.2067 4.29102L42.2067 0.291016C41.8161 -0.0996094 41.1817 -0.0996094 40.7911 0.291016C40.4005 0.681641 40.4005 1.31602 40.7911 1.70664L43.0849 4.00039L1.50049 4.00039C0.947363 4.00039 0.500488 4.44727 0.500488 5.00039C0.500488 5.55352 0.947363 6.00039 1.50049 6.00039L43.0849 6.00039L40.7911 8.29414C40.4005 8.68476 40.4005 9.31914 40.7911 9.70977C41.1817 10.1004 41.8161 10.1004 42.2067 9.70977L46.2067 5.70977V5.70664Z"
                      fill="#0D69D5"
                    />
                  </svg>
                </div>
                <div
                  class="flex flex-col w-2/12 overflow-y-auto scroll-bar"
                  :style="'max-height: 26vh;'"
                >
                  <ul
                    v-for="(status, index) in statusList"
                    :key="index"
                    class="flex"
                  >
                    <div class="flex justify-between items-center gap-5 py-1">
                      <input
                        type="checkbox"
                        class="w-5 h-5 cursor-pointer"
                        :class="{ 'cursor-not-allowed': disabledList }"
                        :value="status.id"
                        v-model="checkedStatusList"
                        :disabled="disabledList"
                        @change="handleListChecked(status.id)"
                      />
                      <StatusBadge
                        :label="status.name"
                        :color="status.colour"
                        customClass="rounded-full"
                      />
                    </div>
                  </ul>
                </div>
                <!-- <div
                  class="flex border-2 items-center justify-center p-3 rounded-lg"
                >
                  <div class="flex justify-between items-center gap-5">
                    <input
                      type="checkbox"
                      class="w-5 h-5 cursor-pointer"
                      v-model="workFlowEnd"
                      @input="handleWorkflowEnd"
                    />
                    <label>Workflow End</label>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-end pt-4">
          <div class="">
            <v-button
              text="Save"
              class="py-2"
              type="primary"
              :loader="statusUpdateLoading"
              @click="updateStatusData"
              :disabled="
                getNameValidationError ||
                  getColorValidationError ||
                  getDescriptionValidationError ||
                  statusUpdateLoading
              "
            />
          </div>
        </div>
      </div>

      <div v-else class="flex w-full flex-col p-4 justify-center items-center">
        <v-loader />
      </div>
    </div>
  </div>
</template>

<script>
import SubHeader from "@/components/SubHeader";
import CreateStatus from "@shared/workflow/components/create-status";
import StatusBadge from "@shared/workflow/components/status-badge";
import Loader from "@shared/components/loader";

import {
  fetchStatusDetails,
  updateStatusTransition,
} from "@shared/workflow/service.js";
import Button from "@shared/components/button";

export default {
  name: "statusConfiguration",
  components: {
    CreateStatus,
    StatusBadge,
    SubHeader,
    "v-button": Button,
    "v-loader": Loader,
  },
  data() {
    return {
      checkedStatusList: [],
      workFlowEnd: "",
      mode: "edit",
      statusDetail: {},
      configLoader: false,
      statusUpdateLoading: false,
    };
  },
  computed: {
    statusList() {
      const allStatusList = this.$store.getters.getWorkflowStateTransitionList;
      return allStatusList.filter(
        (el) => el.id !== this.getStatusId && el.is_active === true
      );
    },
    getStatusId() {
      return this.$route.params.statusId;
    },
    getWorkflowId() {
      return this.$route.params.workflowId;
    },
    getType() {
      return this.$route.params.type;
    },
    getNameValidationError() {
      return this.formData.hasNameError;
    },
    getDescriptionValidationError() {
      return this.formData.hasDescriptionError;
    },
    getColorValidationError() {
      return !this.formData.hasColorError;
    },
    formData() {
      return this.$store.getters.getEditStatusFormData;
    },
    customBreadCrumbs() {
      return [
        { name: this.getType },
        { name: "Create Workflow" },
        { name: "State Transition" },
        { name: "Configure Workflow" },
      ];
    },
    disabledList() {
      return !this.statusDetail?.is_active;
    },
  },
  async mounted() {
    try {
      this.configLoader = true;
      const response = await fetchStatusDetails(this.getType, this.getStatusId);
      if (response.status) {
        this.statusDetail = response?.data?.data;
        // this.$toast.success(response?.data?.success_message);
      }
      this.configLoader = false;
    } catch (err) {
      console.log("err", err);
      this.configLoader = false;
      this.$toast.error(
        err?.response?.data?.status_message || "Something went wrong"
      );
    }
  },
  watch: {
    statusDetail(newVal) {
      if (
        (newVal?.to_status_ids !== "null" ||
          newVal?.to_status_ids.includes(!null)) &&
        newVal?.to_status_ids?.length
      ) {
        this.checkedStatusList = newVal.to_status_ids;
      }
    },
  },
  methods: {
    handleListChecked() {
      this.workFlowEnd = "";
    },
    handleWorkflowEnd() {
      this.checkedStatusList = [];
    },
    async updateStatusData() {
      this.statusUpdateLoading = true;
      try {
        let payload = {
          workflow_id: this.getWorkflowId,
          name: this.formData.statusName?.toString().trim(),
          colour: this.formData.statusColor.id,
          is_active: this.formData.activeStatus,
          from_status_id: this.getStatusId,
          to_status_ids: this.checkedStatusList,
        };
        if (this.formData.statusDescription) {
          payload.description = this.formData.statusDescription
            ?.toString()
            .trim();
        }
        const response = await updateStatusTransition(this.getType, payload);
        if (response.status) {
          this.$toast.success(response?.data?.success_message || "Success");
          this.$router.back();
        }
        this.statusUpdateLoading = false;
      } catch (err) {
        this.statusUpdateLoading = false;
        console.log("err", err);
        this.$toast.error(
          err?.response?.data?.status_message || "Something went wrong"
        );
      }
    },
  },
};
</script>
